import { IdiomaVO } from "./idiomaVO";

/**
 * modelo general para la respuesta de los catalogos.
 */
export class CatalogoResponseVO {
    descripcion: string;
    id: number;
    idNombre: string;
    idioma: IdiomaVO;
}