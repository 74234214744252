import { Component, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Component amenidades created by: Juan Carlos Contreras
 */
@Component({
  selector: 'app-amenidades',
  templateUrl: './amenidades.component.html',
  styles: []
})
export class AmenidadesComponent implements OnInit, AfterContentInit {
  @ViewChild('texto') texto;

  constructor(translate: TranslateService) { 
    translate.setDefaultLang('es');
  }
  
  ngOnInit() {
  }
  ngAfterContentInit(){
    var indexK=(document.getElementById("texto") as HTMLLabelElement);
  }

}
